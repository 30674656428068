// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/DZdGeXE0A";

const cycleOrder = ["v3181kkiG"];

const variantClassNames = {v3181kkiG: "framer-v-1bknes7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, text, width, ...props}) => { return {...props, avSdS9Imn: text ?? props.avSdS9Imn ?? "We reserve the rights to change prices and revise the resources usage policy in any moment."} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, avSdS9Imn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "v3181kkiG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ODxJ7", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1bknes7", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"v3181kkiG"} ref={ref} style={{...style}}><motion.div className={"framer-61ljfn"} data-framer-name={"Round"} layoutDependency={layoutDependency} layoutId={"SskTjNcgH"} style={{backgroundColor: "var(--token-f512b2c0-ccff-4c1c-81ef-5b1a68ff0d48, rgb(178, 238, 81))", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-zrm2jh"} data-styles-preset={"DZdGeXE0A"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>We reserve the rights to change prices and revise the resources usage policy in any moment.</motion.p></React.Fragment>} className={"framer-as7n7b"} data-framer-name={"Paragraph"} layoutDependency={layoutDependency} layoutId={"lrasfddW8"} style={{"--extracted-r6o4lv": "var(--token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9, rgb(66, 68, 63)) ", "--framer-paragraph-spacing": "0px"}} text={avSdS9Imn} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ODxJ7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ODxJ7 .framer-6m4b68 { display: block; }", ".framer-ODxJ7 .framer-1bknes7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 803px; }", ".framer-ODxJ7 .framer-61ljfn { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); position: relative; width: 8px; }", ".framer-ODxJ7 .framer-as7n7b { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ODxJ7 .framer-1bknes7 { gap: 0px; } .framer-ODxJ7 .framer-1bknes7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ODxJ7 .framer-1bknes7 > :first-child { margin-left: 0px; } .framer-ODxJ7 .framer-1bknes7 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 803
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"avSdS9Imn":"text"}
 */
const FramertFmQJeiDs: React.ComponentType<Props> = withCSS(Component, css, "framer-ODxJ7") as typeof Component;
export default FramertFmQJeiDs;

FramertFmQJeiDs.displayName = "Buttet";

FramertFmQJeiDs.defaultProps = {height: 27, width: 803};

addPropertyControls(FramertFmQJeiDs, {avSdS9Imn: {defaultValue: "We reserve the rights to change prices and revise the resources usage policy in any moment.", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramertFmQJeiDs, [...sharedStyle.fonts])